
.carousel {

  margin-bottom: 30px;
  overflow: hidden;

  @include respond-to(medium) {
    height: calc(100vh - #{$navbar-height});
  }

  .carousel-inner > .item > img {
    width: 100%;
  }
}

.carousel-indicators {
  li {
    margin: 1px 10px;
    transform: rotate(45deg);
    border-width: 2px;
    border-radius: 2px;
  }

  .active {
    margin: 1px 10px;
  }

}

// Home page link sizing
.home-page-link,
.home-page-weather-link {
  position: relative;
  height: 125px;
  width: 100%;
  display: block;
  margin-bottom: 15px;
  background-size: cover;
  background-position: center center;
  background-color: $dark-grey;

  @media (min-width: 550px) {
    height: 200px;
  }

  @include respond-to(small) {
    margin-bottom: 30px;
  }

  @include respond-to(medium) {
    height: 230px;
  }

  @include respond-to(large) {
    height: 250px;
  }
}

// Home page Link Images
.home-page-link {
  &.about-us {
    background-image: url(../images/home-about-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-about-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-about-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-about-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-about-lge.jpg);
    }
  }
  &.weather {
    background-image: url(../images/home-weather-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-weather-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-weather-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-weather-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-weather-lge.jpg);
    }
  }
  &.membership {
    background-image: url(../images/home-membership-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-membership-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-membership-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-membership-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-membership-lge.jpg);
    }
  }
  &.restaurant {
    background-image: url(../images/home-restaurant-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-restaurant-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-restaurant-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-restaurant-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-restaurant-lge.jpg);
    }
  }
  &.accommodation {
    background-image: url(../images/home-accom-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-accom-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-accom-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-accom-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-accom-lge.jpg);
    }
  }
  &.news {
    background-image: url(../images/home-news-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-news-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-news-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-news-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-news-lge.jpg);
    }
  }
  &.events {
    background-image: url(../images/home-events-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-events-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-events-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-events-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-events-lge.jpg);
    }
  }
  &.gallery {
    background-image: url(../images/home-gallery-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-gallery-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-gallery-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-gallery-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-gallery-lge.jpg);
    }
  }
  &.racing {
    background-image: url(../images/home-racing-xs1.jpg);
    @media (min-width: 550px) {
      background-image: url(../images/home-racing-xs2.jpg);
    }
    @include respond-to(small) {
      background-image: url(../images/home-racing-sm.jpg);
    }
    @include respond-to(medium) {
      background-image: url(../images/home-racing-md.jpg);
    }
    @include respond-to(large) {
      background-image: url(../images/home-racing-lge.jpg);
    }
  }
}

.home-block-content,
.home-hover-container {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.home-block-content {

  .home-heading {
    position: relative;
    display: inline-block;
    top: 50%;
    transform: translatey(-50%);
    margin: 0;
    font-size: $link-title-mobile;
    color: $white;

    // active/clicked state on link tiles on mobile
    .home-page-link:active &,
    .home-page-link:focus & {
      @include transition(color 0.2s ease);
      color: $dark-grey;
    }

    &:before,
    &:after {
      @include square(7px);
      position: absolute;
      top: 14px;
      content: "";
      border: 2px solid #fff;
      border-radius: 2px;
      transform: rotate(45deg);

      // active/clicked state on link tiles on mobile
      .home-page-link:active &,
      .home-page-link:focus & {
        @include transition(border-color 0.2s ease);
        border-color: $dark-grey;
      }
    }

    &:before {
      left: -20px;
    }

    &:after {
      right: -20px;
    }

    @include respond-to(medium) {
      font-size: $link-title-lge;

      &:before,
      &:after {
        @include square(10px);
        top: 20px;
        border: 2px solid #fff;
        border-radius: 3px;
      }

    }
  }
}

// Disable hover state on home tiles for mobile
.home-page-link {
  .home-hover-container {
    @include transition(opacity 0.8s ease);
    opacity: 0;
  }

  @include respond-to(medium) {
    &:hover {
      .home-block-content {
        display: none;
      }
      .home-hover-container {
        opacity: 1;
      }
    }
  }
}

.home-hover-container {
  background-color: rgba(0, 0, 0, 0.7);

  &:after {
    position: absolute;
    content: "";
    top: 15px;
    left: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    border: 2px solid #fff;
  }

  .home-page-link:hover & {
    color: #fff;

    // Add decorative item to LHS Border
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("../images/button-side-left-inv-sml.png") no-repeat left center;
      left: 15px;
    }

    // Add decorative item to RHS Border
    &:after {
      background: url("../images/button-side-right-inv-sml.png") no-repeat right center;
    }
  }
  // active/clicked state on link tiles
  .home-page-link:active &,
  .home-page-link:focus & {
    @include transition(color 0.2s ease);
    color: $light-grey;
  }
}

.home-link-description {
  font-size: $link-description-med;
  @include respond-to(large) {
    font-size: $link-description-lge;
  }
}

.home-hover-content,
.weather-link-lge{
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .home-heading {
    margin-top: 0;
    font-size: $link-title-hover-med;
    font-family: $headings-font-family;
    text-transform: uppercase;

    @include respond-to(large) {
      font-size: $link-title-hover-lge;
    }
  }
}

//// Weather link styles
//.home-page-weather-link {
//
//  color: $white;
//  &:hover {
//    color: $white;
//  }
//
//  &:after {
//    position: absolute;
//    content: "";
//    top: 15px;
//    left: 15px;
//    height: calc(100% - 30px);
//    width: calc(100% - 30px);
//    border: 2px solid #fff;
//    background: url("../images/button-side-right-inv-sml.png") no-repeat right center;
//  }
//
//  // Add decorative item to LHS Border
//  &:before {
//    content: "";
//    position: absolute;
//    width: 100%;
//    height: 100%;
//    background: url("../images/button-side-left-inv-sml.png") no-repeat left center;
//    left: 15px;
//  }
//
//  .weather-link-sml {
//    display: block;
//
//    @include respond-to(medium) {
//      display: none;
//    }
//  }
//
//  .weather-link-lge {
//    display: none;
//
//    @include respond-to(medium) {
//      display: block;
//    }
//  }
//
//  .button-solid-light {
//    display: inline-block;
//  }
//}



