.table {
  display: table;
  text-align: left;
  border-collapse:collapse;
}

.table-row {
  border-bottom: 1px solid $darker-grey;
  display: block;
  margin-bottom: 5px;


  &.table-header {
    border-bottom: 2px solid $darker-grey;
  }

  > .table-cell:first-of-type {
      .table-cell-label {
        display: none;
      }
  }

  @include respond-to(small) {
    display: table-row;
  }
}

.table-cell {
  @include respond-to(small) {
    display: table-cell;
    padding-top: 8px;
    padding-bottom: 2px;

    .table-cell-label {
      display: none;
    }
  }
}

.table-header {
  font-weight: bold;
  .table-heading {
    display: none;

    &:first-of-type {
      display: table-cell;
    }

    @include respond-to(small) {
      display: table-cell
    }
  }

  .table-cell {
    padding-bottom: 10px;
  }
}

/*.table {
  text-align: left;

  .table-cell-label {
    @include respond-to(small) {
      display: none;
    }
  }
}

.table-cell-first {
  display: none;
}

.table-header {
  margin-bottom: 5px;
  border-bottom: 2px solid #000;

  @include respond-to(small) {
    display: block;
  }
}

.table-heading {
  padding-bottom: 5px;
  font-weight: bold;
  display: none;

  &:first-of-type {
    display: inline-block;
  }

  @include respond-to(small) {
    display: inline-block;
  }
}

.table-row {
  margin-bottom: 5px;
  border-bottom: 2px solid #000;
}

.table-cell {
  @include respond-to(small) {
    display: inline-block;
  }
}*/

//.table-cell-data {
//  &:empty {
//    position: relative;
//    &::after {
//      position: absolute;
//      content: "-";
//
//    }
//  }
//}
