.scv-line {
  display: block;
  height: auto;
  width: 50%;
  margin: 25px auto;

  @include respond-to(medium) {
    max-width: 350px;
    margin: 45px auto;
  }

  img {
    @include img-responsive()
  }
}

hr {
  border-color: #404040;
}