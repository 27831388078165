footer {
  .home & {
    margin-top: 30px;
  }
}

.footer-main {
  display: none;
  padding-top: 90px;
  background-color: $footer-background-colour;

  @include respond-to(medium) {
    display: block;
  }
}

.footer-links {
  padding-top: 45px;
  background-color: $sponsor-logo-background-colour;
  color: $white;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
}

.footer-bar {
  font-size: 12px;
  line-height: $footer-bar-height;
  background-color: $footer-bar-background-colour;

  .social-icons {
    float: left;
  }

  .footer-text-links {
    float: right;
    a {
      color: #fff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.scv-logo-footer {
  width: 250px;
  margin: 0 auto 45px;
  img {
    @include img-responsive();
  }
}

.footer-nav {
  margin-bottom: 45px;
  ul {
    padding: 0;
    text-align: center;
  }
  li {
    display: inline-block;
    margin: 0 10px;
    list-style-type: none;
  }
  a {
    color: $white;
    text-decoration: none;
  }
}

.sponsor-logo {
  @include img-responsive();
}
