// Change Nav Container-fluid to Container on media query
.nav-container {
  @extend .container-fluid;

  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.pre-nav-wrapper {
  display: none;
  @include transition(height .2s ease);
  overflow: hidden;
  height: $pre-nav-height;
  background-color: $pre-nav-background-colour;

  .slim & {
    height:0;
  }

  @include respond-to(medium) {
    display: block;
  }

}

.pre-nav {
  font-size: 12px;
  line-height: 30px;
  display: none;

  .pre-nav-links {
    float: left;
    color: $white;
    margin: 0 $nav-link-horizontal-padding
  }

  a.pre-nav-links:hover {
    text-decoration: underline;
  }

  @include respond-to(medium) {
    display: block;
  }

  // Hide pre-nav when slim class added to body
  .slim & {
    display: none;
  }

  .social-icons {
    float: right;
  }
}

// Styles for search icon in mobile nav
.navbar-toggle .fa {
  font-size: 20px;
  color: $black;
}

.navbar-toggle.collapsed .fa {
  color: $white;
}

// In-page-nav sticky styles

.in-page-nav-container {

  @include transform(translate3d(0px,0px,0px));

  position: absolute;
  left: 0;
  right: 0;
  z-index: $zindex-navbar;
  min-height: $secondary-nav-height;
  background-color: $in-page-nav-background-colour;

  .fixed & {
    position: fixed;
    top: $navbar-height;
    left: 0;
    right: 0;
  }
}

// Main Logo styles
.scv-logo {
  @include transition(all 0.2s ease);
  position: absolute;
  z-index: 1000;
  top: 15px;
  left: calc(50% - 10px);
  transform: translateX(-50%);
  width: 120px;

  img {
    @include img-responsive();
    transform: translateZ(0);
  }

  @include respond-to(medium) {
    width: 130px;
    top: 41px;
    transform: translateX(-45%);
  }
}

// adjust logo size and position when slim class added to body (small screens)
.slim {
  .scv-logo {
    width: 65px;
    top: 5px;
  }
}

.scv-page-header {
  margin-bottom: 85px;
}

