// index.php page styles

.post-item,
.posts-navigation {
  border-top: 2px solid $darker-grey;
  padding-top: 20px;
}

.entry-image {
  width: 100%;
  padding-bottom: 40%;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center center
}

.entry-header {
  margin-bottom: 11px;

  .updated {
    display: inline-block;
    text-transform: uppercase;
    color: $dark-grey;
  }
}

.entry-title {
  font-size: $font-size-h2;
  @include respond-to(medium) {
    font-size: $font-size-h2-large-screen;
  }
}

.excerpt-more-link {
  margin-bottom: 45px;

  a {
    border-bottom: 1px solid $light-grey;

    &:hover,
    &:focus {
      text-decoration: none;
      border-color: $darker-grey;
    }
  }
}

.posts-navigation {
  padding-bottom: 20px;
  overflow: auto;
  font-weight: bold;
  .nav-previous {
    display: inline-block;
    float: right;
  }
  .nav-next {
    display: inline-block;
  }
}

.post-container {
  @include make-md-column-push(3);
  margin-bottom: 170px;
}

.post-category-container {
  @include make-md-column-pull(8);
  margin-bottom: 20px;

  ul {
    padding: 0;
    margin-bottom: 20px;
  }

  li {
    list-style-type: none;
  }
}

///////////////////// Single post page styles

// Previous and Next post navigation
.single {
  .posts-navigation {
    .nav-previous {
      float: none;
    }
    .nav-next {
      display: inline-block;
      float: right;
    }
  }

  .entry-header {
    margin-bottom: 45px;
  }

  .post-item {
    @include respond-to(medium) {
      border-top: none;
      padding-top: 6px;
    }
  }

  .entry-content {
    margin-bottom: 45px;
  }

  .header-image-container {
    background-position: center center;
  }
}



