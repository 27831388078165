
.social-background {
  @extend .fa;
  @extend .fa-circle;
  @extend .fa-stack-2x;
}

.social-icon {
  @extend .fa;
  @extend .fa-stack-1x;
  @extend .fa-inverse;
  @extend .fa-fw;
}

.mobile-social-icons {
  padding: 5px 0;
  .social-background {
    color: $social-icons-mobile-background;
  }
}