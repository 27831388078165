// Navigation fonts
.navbar,
.footer-nav {
  .menu-button,
  li {
    font-family: $navigation-font-family;
    font-size: $font-size-nav-xs;
    text-transform: uppercase;

    @include respond-to(medium) {
      font-size: $font-size-nav-med;
    }

    @include respond-to(large) {
      font-size: $font-size-nav-lge;
    }
  }
}

// Navbar Styles

.navbar-left,
.navbar-right {
  border: none;
}

.navbar-left {
  float: left;
  margin-right: 0;
  margin-left: $navbar-padding-horizontal;
}

.navbar-nav {

  > li > a {
    padding-top: $nav-link-vertical-padding;
    padding-bottom: $nav-link-vertical-padding;
    line-height: $line-height-computed;
  }

  // Uncollapse the nav
  @media (min-width: $grid-float-breakpoint) {
    float: left;
    margin: 0;

    > li {
      float: left;
      > a {
        padding-top: $navbar-padding-vertical;
        padding-bottom: $navbar-padding-vertical;
      }
    }

    > .search {
      > a {
        padding-top: 23px;
        padding-bottom: 23px;
      }
    }
  }

  @include respond-to(large) {
    > .search {
      > a {
        padding-top: 21px;
        padding-bottom: 21px;
      }
    }
  }
}

.navbar-collapse {
  border-top: none;
  box-shadow: none;
}

.navbar-inverse {
  background-color: $main-nav-background-colour;
  border: none;

  .navbar-nav {
    > li {
      a {
        color: $white;
        span {
          display: block;
        }
      }
      .dropdown-menu {
        text-align: center;

        @include respond-to(medium) {
          text-align: left;
          top: 107%;
          border: none;
        }

        a {
          color: $text-color;
          text-transform: capitalize;
          background-color: transparent;

          &:hover {
            color: $light-grey;
          }
          &:focus,
          &:active {
            color: $text-color;
          }
        }
      }

      @include respond-to(medium) {
        border-top: $navbar-active-border-width solid $main-nav-background-colour;
        border-bottom: $navbar-active-border-width solid $main-nav-background-colour;

        &.open {
          border-top: $navbar-active-border-width solid $mobile-nav-active-bg;
          border-bottom: $navbar-active-border-width solid $mobile-nav-active-bg;
        }
      }

      &:hover,
      &:focus {
        color: $light-grey;
        background-color: $navbar-inverse-link-hover-bg;
        @include respond-to(medium) {
          @include transition(border-bottom .3s ease-out);
          border-bottom: $navbar-active-border-width solid $gold
        }
      }
    }
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: $darker-grey;
        color: $white;
      }
    }

    > .active > a {
      &,
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  .navbar-toggle {
    border: none;

    &.navbar-right {
      @include navbar-vertical-align(40px);
    }

    &.navbar-left {
      @include navbar-vertical-align(32px);
    }

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

//remove top and bottom margins for mobile nav styles
.navbar-nav {
  margin: 0 auto;
}

// centre all items in nav
.navbar-collapse {
  text-align: center;
}

.menu-button {
  margin-bottom: 5px;
}

// Mobile Nav Active Styles

.navbar-inverse .navbar-nav>li>a:active {
  background-color: $light-grey;

  @include respond-to(medium) {
    background-color: transparent;
    color: $darker-grey;
  }
}

//In-page Nav Styles

.in-page-nav {
  font-family: $navigation-font-family;
  border-radius: 0;
  border: 0;
  background-color: $in-page-nav-background-colour;
  text-align: center;

  &.navbar {
    min-height: 20px;
    margin-bottom: 0;
  }

  .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top;

    li {
      border: none;
      font-size: $font-size-nav-xs;

      @include respond-to(medium) {
        font-size: $font-size-nav-med;
      }

      @include respond-to(large) {
        font-size: $font-size-nav-lge;
      }

      &:hover {
        border: none;
      }

      a {
        padding: 9px 9px;

        &:hover {
          color: $light-grey;
        }
      }

      &.active {
        a {
          color: $light-grey;
        }
      }
    }

    & > .active {
      & > a {
        background-color: transparent;

        &:hover {
          color: $light-grey;
          background-color: transparent;
        }
      }
    }
  }

  .navbar-toggle {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 9px;
    padding-bottom: 9px;

    span {
      color: $white;
    }
  }

  .navbar .navbar-collapse {
    text-align: center;
  }

  .dropdown-menu {
    display: block;
  }

  .navbar-header {
    position: relative;
    min-height: $secondary-nav-height;
  }

  .current-active,
  .current-event-active {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-transform: uppercase;
    color: $white;

    @include respond-to(medium) {
      display: none;
    }
  }
}

// Active state for mobile search icon
.navbar-inverse .navbar-nav > li.search a {
  color: #000;

  &.collapsed {
    color: $white;
  }
}

// Show dropdowns on main nav on hover (not mobile)
.dropdown:hover {

  @include respond-to(medium) {
    .dropdown-menu {
      display: block;
    }
  }
}
