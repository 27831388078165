// Accordion styles

.accordion-item {

  .accordion-item-title {
    font-weight: bold;
    margin-bottom: 11px;

    .collapsed {
      color: $accordion-item-title;
      &:hover,
      :focus {
        color: $accordion-item-title-hover;
      }
    }

    a {
      color: $accordion-item-title-active;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .accordion-item-content {
    padding : 15px;
    margin-bottom: 15px;
    border-top: 1px solid $darker-grey;
    border-bottom: 1px solid $darker-grey;

    p {
      margin: 0;
    }
  }
}