.page {
  &.events {
    text-align: left;

    .col-clear {
      @include respond-to(small) {
        &:nth-child(2n+1) {
          clear: left;
        }
      }

      @include respond-to(medium) {
        &:nth-child(2n+1) {
          clear: none;
        }
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
}

.event-container {
  margin-bottom: 15px;

  @include respond-to(small) {
    margin-bottom: 30px;
  }
}

.event-image-container {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 75%;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  &:before {
    @include transition(background-color 0.5s ease);
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &:hover,
  &:active {
    &:before {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.event-image-date {
  position: absolute;
  left: 0;
  top: 0;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.3);
  color: $white;
  font-family: $headings-font-family;
  font-size: 22px;
}

.event-details {
  h1 {
    font-size: 26px;
    text-transform: none;

    a {
      text-decoration: none;

    }
  }
}

.event-date {
  margin-bottom: 15px;
}

.event-archive-wrapper {
  margin-bottom: 170px;
}