// Add body padding to compensate for navbar height
body {
  position: relative;
  padding-top: $navbar-height;

  &.fixed {
    padding-top: $navbar-height;// + $secondary-nav-height;
  }
}

// Stop page scrolling when Mobile menu is open (Requires js)
.menu-open {
  overflow: hidden;
}

// Page Hero image responsive sizing
.header-image-container {
  position: relative;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: #eee;

  @include respond-to(small) {
    height: 350px;
  }

  @include respond-to(medium) {
    height: 450px;
  }

  @include respond-to(large) {
    height: 550px;
  }
}

// Show Book Now and Search Menu Items on Larger Screens
.navbar {
  .show-lge {
    display: none;

    @include respond-to(medium) {
      display: block;
    }
  }

  .hide-large {
    @include respond-to(medium) {
      display: none;
    }
  }
}

// Site wide spacing etc

section {
  margin-bottom: 70px;
  @include respond-to(medium) {
    margin-bottom: 170px;
  }

  &:before {
    content: '';
    display: block;
    position: relative;
    margin-top: -140px;
    height: 140px;
    visibility: hidden;
  }

  &.with-hero {
    &:before {
      margin-top: -105px;
      height: 105px;
    }
  }

}

.section-heading {
  margin-bottom: 45px;
  //padding-top: 200px;
  //margin-top: -200px;
}

// Content List Styles

section {
  ul {
    list-style-position: inside;
    list-style-type: none;
    padding: 0;

    li {
      &:before {
        content: ".";
        vertical-align: top;
        font-size: $font-size-base;
        line-height: 1;
        margin-right: 10px;
      }
    }
  }

  img {
    @include img-responsive();
  }
}

hr {
  margin-top: 15px;
  margin-bottom: 15px;
}

// Main page/section headings

.large-heading {
  text-align: center;
}

////// Text Links

// In-content Text Links
section {
  a {
    color: $content-link-colour;
    @include transition(color 0.2s ease);

    &:hover,
    &:focus {
      color: $content-link-hover-colour;
      text-decoration: none;
    }
  }
}

// Other text links

a {
  color: $link-colour;
  @include transition(color 0.2s ease);

  &:hover {
    color: $link-hover-colour;
    text-decoration: none;
  }

  &:focus,
  &:active {
    color: $link-active-colour;
  }
}

// Position message to update old browsers

.update-browser-info {
  margin-top: 70px;
}
