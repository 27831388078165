
/// Search Form Styles

.search-box {

  .search-input,
  .search-submit {
    width: 100%;
    text-transform: uppercase;
    color: #fff;
  }

  .search-input {
    margin-top: 15px;
    margin-bottom: 15px;
    border: 1px solid $light-grey;
    background-color: transparent;
    text-align: center;
    line-height: 30px;
    height: 30px;

    &::placeholder {
      font-size: 12px;
    }
  }

  .search-submit {
    @include transition(all 0.5s ease);
    background-color: $black;
    border: none;
    margin-bottom: 15px;

    &:active {
      background-color: $white;
      color: $black;
    }
  }
}


// Newsletter Sub Form

.sub-form {
  display: none;
  position: absolute;
  bottom: 0;
  right: 20px;
  padding: 20px;
  background-color: $white;

  @include respond-to(small) {
    display: block;
  }

  @include respond-to(medium) {
    display: block;
    right: 30px;
  }

  .sub-input {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    border: none;
    text-align: center;
    line-height: 40px;
    background-color: $light-grey;
  }

  .sub-submit {
    display: block;
    width: 100%;
    margin: 0;
    font-family: $headings-font-family;
    font-size: 22px;
    text-transform: uppercase;
  }
}