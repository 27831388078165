 .button-link {
  text-transform: uppercase;
  font-size: $font-size-h3;
  font-family: $headings-font-family;
  color: $button-dark;
  text-align: center;

  &:hover {
    text-decoration: none;
    color: $button-dark;
  }

  &:active {
    color: $white;
  }
}

.fancy-button {
  position: relative;
  display: block;
  border: 3px solid $button-dark;
  padding: 5px 60px;
  margin: 5px 0;

  &:before {
    position: absolute;
    content: url("../images/button-side-left.png");
    left:-1px;
  }

  &:after {
    position: absolute;
    content: url("../images/button-side-right.png");
    right:-1px;
  }

  &:hover {
    background-color: $light-grey;
  }

  &:active {
    background-color: $black;
    border-color: $white;

    &:before {
      content: url("../images/button-side-left-inv.png");
    }

    &:after {
      content: url("../images/button-side-right-inv.png");
    }
  }

  @include respond-to(small) {
    display: inline-block;
    margin: 5px;
  }

}

.button-solid-light,
.button-solid-dark {
  display: block;
  padding: 5px 15px;
}

.button-solid-dark {
  background-color: $button-dark;
  color: $white;
}

.button-solid-light {
  background-color: $sky-blue;
}

.book-now > a {

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    height: 50%;
    width: 95%;
    background-color: $gold;
  }
}

.navbar {
  .menu-button,
  .social-icon {
    text-decoration: none;

    &:hover,
    &:focus {
      color: $light-grey;
    }
  }
}

