.gallery-link {
  position: relative;
  display: block;
  text-align: left;
  @include respond-to(medium) {
    &:hover {
      .gallery-link-overlay {
        opacity: 1;
      }
    }
  }
}

.gallery-link-overlay {
  @include transition(opacity 0.8s ease);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
}

.gallery-link-title {
  color: $white;
}

.gallery-container {
  a {
    display: inline-block;
  }
}


.gallery-thumb {
  padding: 0;
  box-sizing: border-box;
  border: 5px solid $white;

  img{
    @include img-responsive();
  }
}