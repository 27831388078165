.page {
  text-align: center;
}

.section-hero-image {
  margin-bottom: 45px;
}

// Section heading Image replacement
section {
  .title-image {
    margin: 0 auto;
  }
}

// Search Results

.search {
  .page {
    text-align: left;
  }

  .excerpt-more-link {
    margin-bottom: 30px;
  }

  .pagination {
    display: block;
    border-top: 2px solid $darker-grey;
    border-radius: 0;
    padding: 20px 0;
    margin: 0;
    overflow: auto;
    font-weight: bold;
    .page-numbers {
      margin: 0 10px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.contact {
  .scv-page-header {
    position: relative;
  }

  .address-panel {
    position: absolute;
    z-index: 100;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    border-radius: 5px;

    @include respond-to(small) {
      @include box-shadow(2px 2px 5px $dark-grey);
      top: 30px;
      left: 20px;
      padding: 15px;
      text-align: left;
      background-color: $white;
      transform: none;
    }

    @include respond-to(medium) {
      top: 50px;
    }

    h2 {
      display: none;

      @include respond-to(small) {
        display: block;
      }
    }

    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      white-space: nowrap;
      color: $dark-grey;
      text-shadow: #fff 0 1px 3px;

      @include respond-to(small) {
        font-size: $font-size-base;
        font-weight: 400;
        text-shadow: none;
      }
    }

  }
}

.terms-and-conditions {
  padding-top: 170px;
}

// weather forcast widget styles

.weather-forecast {
  overflow: auto;
  border: 3px solid $darker-grey;
  margin-bottom: 45px;

  br {
    display: none;

    &:nth-of-type(3) {
    display: block;
    }
  }

  img {
    display: inline;
  }

  @include respond-to(small) {
    padding: 15px 0;

    br {
      display: block;
    }

    img {
      margin: 0 auto;
    }

    br {
      &:first-of-type {
        display: none;
      }
    }
  }

}

.weather-forecast-single {
  padding: 15px 0;
  margin: 0 30px;
  border-bottom: 1px solid $darker-grey;

  &:last-of-type {
    border-bottom: none;
  }


  @include respond-to(small) {
    border-right: 1px solid $darker-grey;
    border-bottom: none;
    margin: 0;
    &:last-of-type {
      border-right: none;
    }
  }
}