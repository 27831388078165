// Media Query Mixin

@mixin respond-to($media) {
  /* Landscape phones and up */
  @if $media == x-small {
    @media (min-width: $screen-xs) {
      @content;
    }
  }
    /* Landscape phone to portrait tablet */
  @else if $media == small {
    @media (min-width: $screen-sm-min) {
      @content;
    }
  }
    /* Portrait tablet to landscape and desktop */
  @else if $media == medium {
    @media (min-width: $screen-md-min) {
      @content;
    }
  }
    /* Large desktop */
  @else if $media == large {
    @media (min-width: $screen-lg-min) {
      @content;
    }
  }
}

// Navbar Vertical centre mixin (by padding)

//@mixin navbar-vertical-align-padding($element-height) {
//  margin-top: (($navbar-height - $element-height) / 2);
//  margin-bottom: ((($navbar-height - $element-height) / 2) - $navbar-active-border-width);
//}
//
//@mixin nav-slim-vertical-align($element-height) {
//  padding-top: (($navbar-height-slim - $element-height) / 2);
//  padding-bottom: (($navbar-height-slim - $element-height) / 2);
//}
//
//// Navbar Vertical centre mixin (by margin)
//
//@mixin navbar-slim-vertical-align($element-height) {
//  margin-top: (($navbar-height-slim - $element-height) / 2);
//  margin-bottom: (($navbar-height-slim - $element-height) / 2);
//}
//
//// Pre-nav vertical centre mixin
//@mixin pre-navbar-vertical-align($element-height) {
//  padding-top: (($pre-nav-height - $element-height) / 2);
//  padding-bottom: (($pre-nav-height - $element-height) / 2);
//}
//
// Pre-nav vertical centre mixin
@mixin pre-navbar-vertical-align-margin($element-height) {
  margin-top: (($pre-nav-height - $element-height) / 2);
  margin-bottom: (($pre-nav-height - $element-height) / 2);
}
//
//@mixin nav-toggle-center($element-height) {
//  padding: (($navbar-height - $element-height) / 2);
//
//}
//
// Footer-bar vertical centre mixin
@mixin footer-bar-vertical-align-margin($element-height) {
  margin-top: (($footer-bar-height - $element-height) / 2);
  margin-bottom: (($footer-bar-height - $element-height) / 2);
}