$font-size-h1:                36px;
$font-size-h1-large-screen:   ($font-size-h1 / 0.6); // 60px
$font-size-h2:                28px;
$font-size-h2-large-screen:   floor(($font-size-h2 / 0.6)); // 48px
$font-size-h3:                ceil(($font-size-base * 1.7)) !default; // ~24px

// Heading font Sizes
h1,
.h1 {
  font-size: $font-size-h1;
  @include respond-to(medium) {
    font-size: $font-size-h1-large-screen;
  }
}

h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }


// Primary Nav Fonts
$navigation-font-family: $headings-font-family;
$font-size-nav-xs: 20px; // Nav font on xs and sml screen
$font-size-nav-med: 16px; // Nav font on med screen
$font-size-nav-lge: 20px; // Nav font on large screen

// Header icon sizes
$icon-font-size: 28px;

h1, h2, h3, h4, h5,
h6 {
  font-family: $headings-font-family;
  text-transform: uppercase;
  margin-top: 0;
}

// Search Form

.search-submit {
  font-family: $headings-font-family;
  font-size: $font-size-nav-xs;
}

//Home page Fonts
$link-title-lge: 50px;  // Home Tile Header
$link-title-mobile: 35px;
$link-title-hover-lge: 24px; // Home Tile Hover Header
$link-title-hover-med: 20px;
$link-description-lge: 14px; // Home Tile Hover Description text
$link-description-med: 12px;
